import React from "react"
import { useIntl } from "gatsby-plugin-intl"

import businessUnitUtils from "../../utils/context/business-unit-utils"
import ContextCommonPage from "../../components/context"

const BusinessUnit = () => {
  const intl = useIntl()
  const { entity, formFields } = businessUnitUtils.getProps({ intl })

  return (
    <ContextCommonPage
      entity={entity}
      formFields={formFields}
      prop="business-units"
      stateProp="businessUnits"
      columnsDefinition={{
        code: {
          title: intl.formatMessage({
            id: "page-contexts-business-unit-code-placeholder",
          }),
        },
      }}
      formTitle={intl.formatMessage({ id: "create-new-business-unit" })}
    />
  )
}

export default BusinessUnit
